import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import get from 'lodash/get'
import React from 'react'

import Adsense from '../components/Adsense'
import Calculator from '../components/Calculator'
import Container from '../components/Container'
import Disqus from '../components/Disqus'
import Layout from '../components/Layout'
import PostCard from '../components/PostCard'
import PostListHorizontal from '../components/PostListHorizontal'
import SEO from '../components/SEO'
import Share from '../components/Share'


import './index.less'

class Index extends React.Component {
  render() {
    const partial = this.props.data.mdx

    const posts = get(this, 'props.data.allMdx.edges')

    return (
      <Layout
        location={this.props.location}
        siteMeta={this.props.data.site.siteMetadata}
      >
        <SEO article={false} />
        <Calculator siteMeta={this.props.data.site.siteMetadata} />
        <div className="home-ad">
          <Adsense type="article-top-responsive" />
        </div>
        <div className="home-blog-section">
          <div className="blog-about" id="blog-about">
            <div
              className="info-group"
            >
              <MDXRenderer>{partial.body}</MDXRenderer>
            </div>
          </div>
          <div className="home-ad">
            <Adsense type="article-bottom-responsive" />
          </div>
          <div className="home-ad">
            <Adsense type="article-bottom-responsive" />
          </div>
          <div className="page-share">
            <Share
              title={this.props.data.site.siteMetadata.title}
              pathname={this.props.location.pathname}
            />
          </div>
          <div className="page-comments">
            <Disqus
              location={this.props.location}
              disqusShortname={
                this.props.data.site.siteMetadata.disqusShortname
              }
            />
          </div>
        </div>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
        disqusShortname
      }
    }
    mdx(
      frontmatter: { type: { eq: "partial" }, name: { eq: "index" } }
    ) {
      body
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { eq: "post" } } }
      limit: 5
    ) {
      edges {
        node {
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            type
            description
            image {
              publicURL
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
          }
        }
      }
    }
  }
`
