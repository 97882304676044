import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import './PostCardHorizontal.less'

class PostCardHorizontal extends React.Component {
  render() {
    const image = getImage(this.props.post.frontmatter.image)

    return (
      <article
        className="post-card-hr"
        itemScope
        itemType="https://schema.org/Article"
      >
        <a
          href={this.props.post.fields.slug}
          className="card-link"
          title={this.props.post.frontmatter.title}
        >
          {this.props.post.frontmatter.image ? (
            <figure
              className="card-media"
              itemProp="image"
              itemType="https://schema.org/ImageObject"
            >
              <GatsbyImage
                className="card-image"
                alt={this.props.post.frontmatter.title}
                image={image}
              />
            </figure>
          ) : null}
          <div className="card-text">
            <h3
              className="card-title"
              itemProp="headline"
              style={{ WebkitBoxOrient: 'vertical' }}
            >
              {this.props.post.frontmatter.title}
            </h3>
            <span
              className="card-author"
              itemProp="author"
              itemScope
              itemType="https://schema.org/Person"
            >
              <span itemProp="name">
                {this.props.siteMeta.author}
              </span>
            </span>
            <span className="card-reading-time">
              {this.props.post.timeToRead} mins
            </span>
            <p
              className="card-description"
              style={{ WebkitBoxOrient: 'vertical' }}
            >
              {this.props.post.frontmatter.description}
            </p>
          </div>
        </a>
      </article>
    )
  }
}

export default PostCardHorizontal
